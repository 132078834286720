import React, { useMemo } from "react";
import { Box, Button, Stack } from "@mui/material";
import * as SC from "./LandloardMyPropertyPage.style";
import { DataGrid } from "UI/DataGrid";
import { SearchField } from "UI/SearchField";
import { useIsMobilePortraitScreen } from "hooks/useIsMobilePortraitScreen";
import { useAdminPropertyPage } from "./AdminPropertyPage.hook";
import { capitalizeFirstLetter } from "utils/helpers";
import { EditBuildingFeeDialog } from "components/EditBuildingFeeDialog";

export const AdminPropertyPage = () => {
  const isMobilePortraitScreen = useIsMobilePortraitScreen();

  const {
    rows,
    loading,
    sorter,
    handleSetSorter,
    search,
    clearSearch,
    handleChangeSearch,
    handleClickRow,
    getSearchFilterHandler,
    sortHandler,
    loadBuildingList,
    selectedBuilding,
    closeEditBuildingFeeDialog,
  } = useAdminPropertyPage();

  /** @type {import("UI/DataGrid.types").Columns} */
  const columns = useMemo(
    () => [
      { name: "id", searchDisabled: true },
      {
        name: "name",
        label: "Property",
        xs: 2.5,
        render: (row) => <Box ml={2}>{row?.name}</Box>,
      },
      {
        name: "landlordName",
        label: "Landloard",
        xs: 2.3,
      },
      {
        name: "landlordEmail",
        label: "Email",
        xs: 3.2,
      },
      {
        name: "processingFee",
        label: "Fee",
        xs: 0.8,
        align: "center",
        render: (row) => (
          <>{row?.processingFee ? `${row.processingFee}%` : "-"}</>
        ),
      },
      {
        name: "feePayer",
        label: "Fee Payer",
        xs: 1.2,
        align: "center",
        render: (row) => (
          <>{row?.feePayer ? capitalizeFirstLetter(row.feePayer) : "-"}</>
        ),
      },
      {
        xs: 2,
        align: "center",
        render: (row) => (
          <Button
            variant="contained"
            size="small"
            onClick={() => handleClickRow(row)}
          >
            Edit
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /** @type {import("UI/DataGrid.types").Columns} */
  const mobileColumns = useMemo(
    () => [
      { name: "id", searchDisabled: true },
      {
        name: "name",
        label: "Property",
        xs: 3,
        render: (row) => <Box ml={2}>{row?.name}</Box>,
      },
      {
        name: "landlordName",
        label: "Landloard",
        xs: 3.2,
      },
      {
        name: "processingFee",
        label: "Fee",
        xs: 1.2,
        align: "center",
        render: (row) => (
          <>{row?.processingFee ? `${row.processingFee}%` : "-"}</>
        ),
      },
      {
        name: "feePayer",
        label: "Fee Payer",
        xs: 1.7,
        align: "center",
        render: (row) => (
          <>{row?.feePayer ? capitalizeFirstLetter(row.feePayer) : "-"}</>
        ),
      },
      {
        xs: 2.6,
        align: "center",
        render: (row) => (
          <Button
            variant="contained"
            size="small"
            onClick={() => handleClickRow(row)}
          >
            Edit
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (loading) return null;

  return (
    <SC.Container>
      {isMobilePortraitScreen ? (
        <Stack direction={"column"} justifyContent={""} gap={2}>
          <SearchField
            search={search}
            clearSearch={clearSearch}
            handleChangeSearch={handleChangeSearch}
          />
        </Stack>
      ) : (
        <Stack direction={"row"} justifyContent={""} gap={2}>
          <SearchField
            search={search}
            clearSearch={clearSearch}
            handleChangeSearch={handleChangeSearch}
          />
        </Stack>
      )}
      <DataGrid
        columns={isMobilePortraitScreen ? mobileColumns : columns}
        rows={rows.filter(getSearchFilterHandler(columns)).sort(sortHandler)}
        sorter={sorter}
        handleSetSorter={handleSetSorter}        
      />
      <EditBuildingFeeDialog
        selectedBuilding={selectedBuilding}
        refreshList={loadBuildingList}
        handleClose={closeEditBuildingFeeDialog}
      />
    </SC.Container>
  );
};
