import { colors } from "@mui/material";

export const customColors = {
  menu: {
    selected: {
      text: "#007AFF",
      background: "rgba(240, 244, 255, 1)",
    },
    hover: {
      background: "rgba(240, 244, 255, 1)",
    },
    notSelected: {
      text: "#1C1C1C",
      background: "rgba(93, 96, 221, 0)",
    },
  },
  text: {
    plain: "#000",
    inactive: colors.grey[500],
    highlighted: "#007AFF",
    highlightedHover: colors.blue[800],
  },
  hover: "#eff7ff",
  border: colors.grey[300],
  detailsBorder: colors.grey[400],
  title: {
    plain: "#000",
  },
  info: "#007AFF",
};
